import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Box, Container, IconButton, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ImageGame00 from '../_files/home/game00.png';
import ImageGame01 from '../_files/home/game01.png';
import ImageGame02 from '../_files/home/game02.png';
import ImageGame03 from '../_files/home/game03.png';
import ImageGame04 from '../_files/home/game04.png';
import ImageGame05 from '../_files/home/game05.png';
import ImageGame06 from '../_files/home/game06.png';
import ImageGame07 from '../_files/home/game07.png';
import ImageGame08 from '../_files/home/game08.png';
import CompanyLogo from '../_files/kings-games-logo-middle.png';
/**
 * `Home` is a React functional component that renders a carousel of images.
 * It utilizes the `useTranslation` hook for internationalization, manages the active index
 * of the carousel images, and handles the fading animation state.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function Home() {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const images = [
    ImageGame00,
    ImageGame01,
    ImageGame02,
    ImageGame03,
    ImageGame04,
    ImageGame05,
    ImageGame06,
    ImageGame07,
    ImageGame08
  ];
  // Subtitles array (parallel to images array)
  const subtitles = [
    t('Subtitle0'),
    t('Subtitle1'),
    t('Subtitle2'),
    t('Subtitle3'),
    t('Subtitle4'),
    t('Subtitle5'),
    t('Subtitle6'),
    t('Subtitle7'),
    t('Subtitle8')
  ];
  const [isFading, setIsFading] = useState(false);

  /**
   * Handles the click event on the arrow icons to navigate through the carousel.
   * Updates the active image index and triggers the fading effect.
   *
   * @param {'next' | 'prev'} direction - The direction of the carousel navigation, either 'next' or 'prev'.
   */
  const handleArrowClick = (direction) => {
    setIsFading(true);
    setActiveIndex((prev) =>
      direction === 'next'
        ? (prev + 1) % images.length
        : (prev - 1 + images.length) % images.length
    );
  };

  return (
    <Container maxWidth="lg">
      <Paper
        elevation={4}
        sx={{
          my: 4,
          p: 3,
          mt: 8,
          borderRadius: 2,
          backgroundColor: 'background.default',
          backgroundImage: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
        }}>
        <Box
          component="img"
          sx={{
            height: 300,
            width: 'auto',
            maxWidth: '100%'
          }}
          alt="Company Logo"
          src={CompanyLogo}
        />
      </Paper>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%', width: '100%' }}>
        <Paper
          elevation={4}
          sx={{
            my: 4,
            p: 3,
            borderRadius: 2,
            backgroundColor: 'background.default',
            backgroundImage: 'none',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            width: '100%'
          }}>
          <Box my={4} textAlign="center">
            <Typography variant="h5" gutterBottom color="primary.main" sx={{ mb: 4 }}>
              {t('Heading0')}
            </Typography>
            <Typography variant="h6" gutterBottom color="primary.main" sx={{ mt: 2 }}>
              {t('Heading1')}
            </Typography>
            <Typography variant="h6" gutterBottom color="primary.main" sx={{ mt: 2 }}>
              {t('Heading2')}
            </Typography>
            <Typography variant="h6" gutterBottom color="primary.main" sx={{ mt: 2 }}>
              {t('Heading3')}
            </Typography>
            <Box
              sx={{
                position: 'relative',
                height: {
                  xs: '270px', // height for extra-small screens (mobile devices)
                  sm: '300px', // height for small screens (small tablets)
                  md: '450px', // height for medium screens (large tablets)
                  lg: '510px' // height for large screens (desktop)
                },
                mt: 5,
                width: '100%',
                overflow: 'hidden'
              }}>
              {images.map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: index === activeIndex ? 1 : 0,
                    transition: isFading ? 'opacity 1s' : 'none'
                  }}>
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'inline-flex',
                      alignItems: 'center',
                      padding: '5px',
                      background:
                        'radial-gradient(circle, rgb(255, 215, 0) 0%, rgb(238, 198, 0) 25%, rgb(218, 165, 32) 50%, rgb(184, 134, 11) 75%)',
                      borderRadius: '10px'
                    }}>
                    <IconButton
                      onClick={() => handleArrowClick('prev')}
                      aria-label={t('PreviousSlide')}
                      sx={{
                        position: 'absolute',
                        left: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 2
                      }}>
                      <ArrowCircleLeftIcon
                        color="primary"
                        style={{ fontSize: '50px' }}
                      />
                    </IconButton>
                    <img
                      src={image}
                      alt={`Slide ${index}`}
                      style={{
                        display: 'block',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        borderRadius: '10px',
                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)'
                      }}
                    />
                    <IconButton
                      onClick={() => handleArrowClick('next')}
                      aria-label={t('NextSlide')}
                      sx={{
                        position: 'absolute',
                        right: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 2
                      }}>
                      <ArrowCircleRightIcon
                        color="primary"
                        style={{ fontSize: '50px' }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          {/* Subtitle displayed below the carousel */}
          <Typography
            variant="subtitle1"
            gutterBottom
            color="primary.main"
            sx={{ mt: 2, textAlign: 'center' }}>
            {subtitles[activeIndex]}
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
}
