/**
 * i18n configuration for internationalization.
 * Uses `react-i18next` library to setup language resources and detection.
 *
 * Imports translation resources from separate files and initializes i18next with these resources.
 * Also sets the initial language based on the user's preference.
 */
import * as i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { getLanguagePreference } from '../utilities/utilities';
import de from './de';
import en from './en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: en,
      de: de
    },
    lng: getLanguagePreference(),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });
