// Sidebar.js
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export default function Sidebar({ collapsed, toggleDrawer, drawerWidth, theme }) {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  const { t } = useTranslation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          overflowX: 'hidden',
          bgcolor: theme.palette.background.default,
          boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.5)'
        }
      }}>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              '&:hover': { backgroundColor: 'secondary.main' },
              color: 'primary.main'
            }}
            onClick={toggleDrawer}>
            <ListItemIcon>
              {collapsed ? (
                <ChevronRightIcon sx={{ color: 'primary.main' }} />
              ) : (
                <ChevronLeftIcon sx={{ color: 'primary.main' }} />
              )}
            </ListItemIcon>
            {!collapsed && <ListItemText primary={t('Collapse')} />}
          </ListItemButton>
        </ListItem>
        {[
          { icon: HomeIcon, text: 'Home', path: '/home' },
          { icon: AnnouncementIcon, text: 'News', path: '/news' },
          { icon: VideogameAssetIcon, text: 'AboutTheGame', path: '/about-the-game' },
          { icon: LiveHelpIcon, text: 'Faq', path: '/faq' },
          { icon: AlternateEmailIcon, text: 'Contact', path: '/contact' },
          { icon: GroupIcon, text: 'AboutUs', path: '/about-us' }
        ].map(({ icon: Icon, text, path }, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              sx={{
                '&:hover': { backgroundColor: 'secondary.main' },
                backgroundColor: isActive(path) ? 'secondary.light' : 'inherit',
                color: isActive(path) ? 'secondary.contrastText' : 'primary.main'
              }}
              component={Link}
              to={path}>
              <ListItemIcon>
                <Icon sx={{ color: 'primary.main' }} />
              </ListItemIcon>
              {!collapsed && <ListItemText primary={t(text)} />}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

Sidebar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
};
