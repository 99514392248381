// faqData.js
const allFaqData = (t) => ({
  General: [
    { question: t('WhatIsIt'), answer: t('WhatIsItAnswer') },
    { question: t('WhoMadeIt'), answer: t('WhoMadeItAnswer') },
    { question: t('Publisher'), answer: t('PublisherAnswer') },
    { question: t('WhereToAcquire'), answer: t('WhereToAcquireAnswer') },
    { question: t('WhatLang'), answer: t('WhatLangAnswer') },
    { question: t('WhatCreatedWith'), answer: t('WhatCreatedWithAnswer') },
    {
      question: t('WhichPatternsArchitectures'),
      answer: t('WhichPatternsArchitecturesAnswer')
    }
  ]
  // Billing: [{ question: t('Question02'), answer: t('Answer02') }]
});

export default allFaqData;
