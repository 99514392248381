/**
 * Retrieves a cookie object by its name.
 *
 * @param {string} name The name of the cookie to retrieve.
 * @return {Object|null} The parsed cookie object, or null if not found or parsing fails.
 */
function getCookieObject(name) {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith(name + '='));

  if (cookieValue) {
    const cookieData = decodeURIComponent(cookieValue.split('=')[1]);
    try {
      return JSON.parse(cookieData);
    } catch (e) {
      console.error('Error parsing cookie:', e);
      return null;
    }
  }
  return null;
}

/**
 * Sets a cookie with the specified name and object value.
 *
 * @param {string} name The name of the cookie.
 * @param {Object} value The object value to be stored in the cookie.
 * @param {number} [daysToExpire=365] The number of days until the cookie expires.
 */
function setCookieObject(name, value, daysToExpire = 365) {
  const date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();

  // Serialize the value object to JSON
  const serializedValue = encodeURIComponent(JSON.stringify(value));

  document.cookie =
    name + '=' + serializedValue + ';' + expires + ';path=/;SameSite=None;Secure';
}

/**
 * Sets the user's language preference in the cookie.
 *
 * @param {string} language The language preference to set.
 */
function setLanguagePreference(language) {
  const settings = getCookieObject('userSettings') || {};
  settings.language = language;
  setCookieObject('userSettings', settings);
}
/**
 * Sets the user's theme preference in the cookie.
 *
 * @param {string} theme The theme preference to set.
 */
function setThemePreference(theme) {
  const settings = getCookieObject('userSettings') || {};
  settings.theme = theme;
  setCookieObject('userSettings', settings);
}

/**
 * Sets the user's preference for the sidebar collapsed state in the cookie.
 *
 * @param {boolean} collapsed The collapse preference to set.
 */
function setCollapsePreference(collapsed) {
  const settings = getCookieObject('userSettings') || {};
  settings.collapsed = collapsed;
  setCookieObject('userSettings', settings);
}

/**
 * Checks if the consent cookie is present and returns the consent status.
 *
 * @return {boolean} The consent status.
 */
function checkCookieConsent() {
  const settings = getCookieObject('userSettings');
  return settings ? settings.consent === true : false;
}

/**
 * Checks the user's preference for the sidebar collapsed state from the cookie.
 *
 * @return {boolean} The collapsed state preference.
 */
function checkCollapsePreference() {
  const settings = getCookieObject('userSettings');
  return settings ? settings.collapsed === true : false;
}

/**
 * Checks the theme preference from the cookie and returns the theme setting.
 *
 * @return {string} The theme setting ('light' or 'dark').
 */
function checkThemePreference() {
  const settings = getCookieObject('userSettings');
  return settings && settings.theme ? settings.theme : 'light';
}

/**
 * Retrieves the user's language preference from the cookie.
 * This function checks the user settings stored in a cookie and extracts the preferred language.
 * If the language setting is not found or the cookie does not exist, it defaults to 'en' (English).
 *
 * @returns {string} The language preference of the user, defaults to 'en'.
 */
function getLanguagePreference() {
  const settings = getCookieObject('userSettings');
  return settings && settings.language ? settings.language : 'en';
}

export {
  checkCollapsePreference,
  checkCookieConsent,
  checkThemePreference,
  getLanguagePreference,
  setCollapsePreference,
  setCookieObject,
  setLanguagePreference,
  setThemePreference
};
