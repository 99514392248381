// Contact.js
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InstagramIcon from '@mui/icons-material/Instagram';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
/**
 * Copies the provided text to the clipboard.
 * @param {string} text The text to be copied to the clipboard.
 */
const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

/**
 * Represents a clickable contact link with an option to copy its content.
 * @param {object} props The component props.
 * @param {string} props.href The URL or email address.
 * @param {node} props.icon The icon to display next to the text.
 * @param {string} props.text The display text.
 * @param {string} props.tooltip The tooltip text for the copy icon.
 */
const ContactLink = ({ href, icon, text, tooltip, newTab }) => (
  <Grid item xs={12}>
    <Card
      sx={{
        position: 'relative',
        backgroundColor: 'background.paper',
        color: 'primary.paper',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
      }}>
      <CardActionArea
        component={newTab ? 'a' : RouterLink}
        href={href}
        to={href}
        target={newTab ? '_blank' : ''}
        rel={newTab ? 'noopener noreferrer' : ''}>
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
            // '&:hover': {
            //   backgroundColor: 'secondary.main'
            // }
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '80%' }}>
            {icon}
            <Typography
              variant="body1"
              sx={{
                ml: 2,
                fontSize: '20px'
              }}>
              {text}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <Tooltip
        title={tooltip}
        sx={{
          position: 'absolute',
          right: 16,
          top: '50%',
          transform: 'translateY(-50%)'
        }}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            copyToClipboard(href);
          }}>
          <ContentCopyIcon sx={{ color: 'primary.paper' }} />
        </IconButton>
      </Tooltip>
    </Card>
  </Grid>
);

ContactLink.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  newTab: PropTypes.bool
};

/**
 * Contact component displaying various contact options like email, social media, and troubleshooting guide.
 * Each option includes a clickable link and a copy-to-clipboard feature.
 *
 * @param {object} props The component props.
 * @param {string} props.supportEmail The support email address.
 * @param {string} props.troubleshootingGuideUrl The URL for the troubleshooting guide.
 * @param {string} props.instagramUrl The Instagram profile URL.
 * @param {string} props.facebookUrl The Facebook page URL.
 */
export default function Contact({
  supportEmail,
  troubleshootingGuideUrl,
  instagramUrl,
  facebookUrl
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const gridSpacing = {
    // Apply consistent margin-bottom to all Card components
    '& .MuiCard-root': {
      marginBottom: 1, // Adjust this value as needed for spacing
      [theme.breakpoints.down('sm')]: {
        // When the theme hits 'small' and below
        marginBottom: 4 // Adjust this value as needed for spacing in mobile view
      }
    }
  };
  return (
    <Container maxWidth="lg">
      <Paper
        elevation={4}
        sx={{
          my: 4,
          p: 3,
          borderRadius: 2,
          backgroundColor: 'background.default',
          backgroundImage: 'none',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
        }}>
        <Box my={4}>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            sx={{ color: 'primary.main' }}>
            {t('GetInTouch')}
          </Typography>
          <Grid container direction="column" sx={gridSpacing}>
            <ContactLink
              href={troubleshootingGuideUrl}
              icon={<HelpOutlineIcon sx={{ color: 'primary.paper' }} />}
              text={t('TroubleShootingLink')}
              tooltip={t('CopyUrl')}
            />
            <ContactLink
              href={`mailto:${supportEmail}`}
              icon={<EmailIcon sx={{ color: 'primary.paper' }} />}
              text={`${t('EmailUsAt')}`}
              tooltip={t('CopyEmail')}
            />
          </Grid>
          <Grid container direction="column" spacing={1} sx={{ mt: 4 }}>
            <ContactLink
              href={instagramUrl}
              icon={<InstagramIcon sx={{ color: 'primary.paper' }} />}
              text={t('FollowUsOnInsta')}
              tooltip={t('CopyUrl')}
              newTab={true}
            />
            <ContactLink
              href={facebookUrl}
              icon={<FacebookIcon sx={{ color: 'primary.paper' }} />}
              text={t('FollowUsOnFb')}
              tooltip={t('CopyUrl')}
              newTab={true}
            />
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}

Contact.propTypes = {
  supportEmail: PropTypes.string,
  troubleshootingGuideUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  facebookUrl: PropTypes.string
};

Contact.defaultProps = {
  supportEmail: 'support@example.com',
  troubleshootingGuideUrl: '/troubleshooting',
  instagramUrl: 'https://instagram.com/',
  facebookUrl: 'https://facebook.com/',
  newTab: false
};
