import { default as styled } from 'styled-components';

export const AppStyles = styled.div`
  .hyphenate {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    hyphens: auto;
  }
`;
