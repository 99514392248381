import NewsIcon from '@mui/icons-material/Announcement';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ContactIcon from '@mui/icons-material/ContactMail';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupIcon from '@mui/icons-material/Group';
import FaqIcon from '@mui/icons-material/HelpOutline';
import HomeIcon from '@mui/icons-material/Home';
import LanguageIcon from '@mui/icons-material/Language';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import GameIcon from '@mui/icons-material/SportsEsports';
import {
  AppBar,
  Collapse,
  Dialog,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Toolbar,
  Typography
} from '@mui/material';
import * as i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getLanguagePreference, setLanguagePreference } from '../utilities/utilities';

/**
 * MobileNavigation is a component for displaying a full-screen navigation menu in mobile view.
 * It provides a list of navigation items and additional settings like language and dark mode toggle.
 *
 * Props:
 *  - darkMode: A boolean indicating whether dark mode is enabled.
 *  - toggleDarkMode: A function to toggle the dark mode state.
 */
export default function MobileNavigation({ darkMode, toggleDarkMode }) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const navigate = useNavigate();
  const isActive = (path) => location.pathname === path;
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguagePreference());

  /**
   * Navigates to a specified path and closes the navigation dialog.
   *
   * @param {string} path - The path to navigate to.
   */
  const handleNavigate = (path) => {
    navigate(path);
    setDialogOpen(false);
  };

  /**
   * Toggles the visibility of the language selection menu.
   */
  const toggleLanguageMenu = () => {
    setLanguageMenuOpen(!languageMenuOpen);
  };

  /**
   * Handles language change and updates the preference in the cookie.
   * @param {string} language The selected language.
   */
  const handleSetLanguage = (language) => {
    setLanguagePreference(language);
    i18n.changeLanguage(language);
    setSelectedLanguage(language); // Update selected language
    setLanguageMenuOpen(false);
  };

  /**
   * Toggles the dark mode state.
   */
  const handleDarkModeToggle = () => {
    toggleDarkMode();
  };

  return (
    <>
      <AppBar
        position="relative"
        sx={{
          backgroundColor: 'background.default',
          color: 'primary.main',
          backgroundImage: 'none',
          boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
        }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDialogOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Dialog fullScreen open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <AppBar
          sx={{
            position: 'relative',
            backgroundColor: 'background.default',
            color: 'primary.main',
            backgroundImage: 'none',
            boxShadow:
              '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
          }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setDialogOpen(false)}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Menu
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <ListItemButton
            sx={{
              '&:hover': {
                color: 'inherit'
              },
              backgroundColor: isActive('/home') ? 'secondary.light' : 'inherit',
              color: isActive('/home') ? 'primary.main' : 'inherit'
            }}
            onClick={() => handleNavigate('/home')}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={t('Home')} />
          </ListItemButton>
          <ListItemButton
            sx={{
              '&:hover': {
                color: 'inherit'
              },
              backgroundColor: isActive('/news') ? 'secondary.light' : 'inherit',
              color: isActive('/news') ? 'primary.main' : 'inherit'
            }}
            onClick={() => handleNavigate('/news')}>
            <ListItemIcon>
              <NewsIcon />
            </ListItemIcon>
            <ListItemText primary={t('News')} />
          </ListItemButton>
          <ListItemButton
            sx={{
              '&:hover': {
                color: 'inherit'
              },
              backgroundColor: isActive('/about-the-game')
                ? 'secondary.light'
                : 'inherit',
              color: isActive('/about-the-game') ? 'primary.main' : 'inherit'
            }}
            onClick={() => handleNavigate('/about-the-game')}>
            <ListItemIcon>
              <GameIcon />
            </ListItemIcon>
            <ListItemText primary={t('AboutTheGame')} />
          </ListItemButton>
          <ListItemButton
            sx={{
              '&:hover': {
                color: 'inherit'
              },
              backgroundColor: isActive('/faq') ? 'secondary.light' : 'inherit',
              color: isActive('/faq') ? 'primary.main' : 'inherit'
            }}
            onClick={() => handleNavigate('/faq')}>
            <ListItemIcon>
              <FaqIcon />
            </ListItemIcon>
            <ListItemText primary={t('Faq')} />
          </ListItemButton>
          <ListItemButton
            sx={{
              '&:hover': {
                color: 'inherit'
              },
              backgroundColor: isActive('/contact') ? 'secondary.light' : 'inherit',
              color: isActive('/contact') ? 'primary.main' : 'inherit'
            }}
            onClick={() => handleNavigate('/contact')}>
            <ListItemIcon>
              <ContactIcon />
            </ListItemIcon>
            <ListItemText primary={t('Contact')} />
          </ListItemButton>
          <ListItemButton
            sx={{
              '&:hover': {
                color: 'inherit'
              },
              backgroundColor: isActive('/about-us') ? 'secondary.light' : 'inherit',
              color: isActive('/about-us') ? 'primary.main' : 'inherit'
            }}
            onClick={() => handleNavigate('/about-us')}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={t('AboutUs')} />
          </ListItemButton>
          {/* ... other navigation items ... */}
          <ListItemButton onClick={toggleLanguageMenu}>
            <ListItemIcon>
              <LanguageIcon />
            </ListItemIcon>
            <ListItemText primary={t('Language')} />
            {languageMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={languageMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                style={{ paddingLeft: selectedLanguage === 'en' ? '70px' : '70px' }}
                onClick={() => {
                  handleSetLanguage('en');
                }}>
                <ListItemText primary="English" />
                {selectedLanguage === 'en' && (
                  <CheckCircleOutlineIcon
                    fontSize="small"
                    style={{ marginLeft: '8px' }}
                  />
                )}
              </ListItemButton>
              <ListItemButton
                style={{ paddingLeft: selectedLanguage === 'de' ? '70px' : '70px' }}
                onClick={() => {
                  handleSetLanguage('de');
                }}>
                <ListItemText primary="German" />
                {selectedLanguage === 'de' && (
                  <CheckCircleOutlineIcon
                    fontSize="small"
                    style={{ marginLeft: '8px' }}
                  />
                )}
              </ListItemButton>
            </List>
          </Collapse>

          {/* ... other navigation items ... */}
          <ListItemButton onClick={handleDarkModeToggle}>
            <ListItemIcon>
              <SettingsBrightnessIcon />
            </ListItemIcon>
            <ListItemText primary={t('DarkLightMode')} />

            <LightModeIcon
              sx={{ color: darkMode ? 'action.disabled' : 'secondary.main' }}
            />
            <Switch
              edge="end"
              checked={darkMode}
              onChange={handleDarkModeToggle}
              onClick={(e) => e.stopPropagation()}
            />
            <DarkModeIcon
              sx={{ color: darkMode ? 'secondary.main' : 'action.disabled' }}
            />
          </ListItemButton>
        </List>
      </Dialog>
    </>
  );
}

MobileNavigation.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  toggleDarkMode: PropTypes.func.isRequired
};
