// AboutTheGame.js
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Card,
  CardContent,
  Container,
  Fab,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default function AboutTheGame() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  const handleClick = useCallback(() => {
    const anchor = document.querySelector('#GameDescription');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Container maxWidth="lg">
      <Paper
        elevation={4}
        sx={{
          my: 4,
          p: 3,
          borderRadius: 2,
          backgroundColor: 'background.default',
          backgroundImage: 'none',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
        }}>
        <Box my={4}>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            color="primary.main"
            id="GameDescription">
            {t('AboutTheGame')}
          </Typography>

          {/* Game Description Section */}
          <Card
            sx={{
              my: 4,
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              position: 'relative',
              backgroundColor: 'background.paper',
              color: 'primary.paper'
            }}>
            <CardContent sx={{ mr: 1, ml: 1, mt: 1 }}>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                className="hyphenate">
                {t('GameDescriptionHeading')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                gutterBottom
                className="hyphenate">
                {t('GameDescriptionSubHeading0')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('GameDescriptionText0')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('GameDescriptionSubHeading1')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('GameDescriptionText1')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('GameDescriptionSubHeading2')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('GameDescriptionText2')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('GameDescriptionSubHeading3')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('GameDescriptionText3')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('GameDescriptionSubHeading4')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('GameDescriptionText4')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('GameDescriptionSubHeading5')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('GameDescriptionText5')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('GameDescriptionSubHeading6')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('GameDescriptionText6')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify', mt: 3 }}>
                {t('GameDescriptionText7')}
              </Typography>
            </CardContent>
          </Card>

          {/* Storyline and Setting Section */}
          <Card
            sx={{
              my: 4,
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              position: 'relative',
              backgroundColor: 'background.paper',
              color: 'primary.paper'
            }}>
            <CardContent>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                className="hyphenate">
                {t('Storyline')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('StorylineHeading')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('StorylineText0')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify', mt: 3 }}>
                {t('StorylineText1')}
              </Typography>
            </CardContent>
          </Card>

          {/* Key Features Section */}
          <Card
            sx={{
              my: 4,
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              position: 'relative',
              backgroundColor: 'background.paper',
              color: 'primary.paper'
            }}>
            <CardContent>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                className="hyphenate">
                {t('KeyFeatures')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                gutterBottom
                className="hyphenate">
                {t('KeyFeatureSubHeading0')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('KeyFeatureText0')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('KeyFeatureSubHeading1')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('KeyFeatureText1')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('KeyFeatureSubHeading2')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('KeyFeatureText2')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('KeyFeatureSubHeading3')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('KeyFeatureText3')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('KeyFeatureSubHeading4')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('KeyFeatureText4')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('KeyFeatureSubHeading5')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('KeyFeatureText5')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('KeyFeatureSubHeading6')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('KeyFeatureText6')}
              </Typography>
            </CardContent>
          </Card>

          {/* Screenshots Section */}
          {/* Placeholder for screenshots */}

          {/* Gameplay Trailer Section */}
          <Card
            sx={{
              my: 4,
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              position: 'relative',
              backgroundColor: 'background.paper',
              color: 'primary.paper'
            }}>
            <CardContent>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                className="hyphenate">
                {t('Trailer')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                gutterBottom
                className="hyphenate">
                {t('TrailerText0')}
              </Typography>
            </CardContent>
          </Card>

          {/* Character Profiles Section */}
          <Card
            sx={{
              my: 4,
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              position: 'relative',
              backgroundColor: 'background.paper',
              color: 'primary.paper'
            }}>
            <CardContent>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                id="CharacterProfiles">
                {t('CharacterProfiles')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                gutterBottom
                className="hyphenate">
                {t('CharacterName0')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('CharacterText0')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('CharacterName1')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('CharacterText1')}
              </Typography>
              <Typography
                variant="h5"
                component="h3"
                sx={{ mt: 5 }}
                gutterBottom
                className="hyphenate">
                {t('CharacterName2')}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: '20px', textAlign: 'justify' }}>
                {t('CharacterText2')}
              </Typography>
            </CardContent>
          </Card>

          {/* System Requirements Section */}
          <Card
            sx={{
              my: 4,
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              position: 'relative',
              backgroundColor: 'background.paper',
              color: 'primary.paper'
            }}>
            <CardContent>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                className="hyphenate">
                {t('SysReq')}
              </Typography>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="OS tabs"
                  sx={{
                    '.MuiTab-root': {
                      // This targets all Tab components
                      color: 'primary.paper'
                    },
                    '.Mui-selected': {
                      // This targets the selected Tab
                      color: 'seconday.paper'
                    },
                    '.MuiTabs-indicator': {
                      backgroundColor: 'background.default'
                    }
                  }}>
                  <Tab label="Windows" />
                  <Tab label="macOS" />
                  <Tab label="Linux" />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                {/* Windows Requirements */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      component="h3"
                      gutterBottom
                      className="hyphenate">
                      {t('SysReqMin')}
                    </Typography>
                    <Typography>Windows Vista {t('OrNewer')}</Typography>
                    <Typography>2 Ghz</Typography>
                    <Typography>2 GB RAM</Typography>
                    <Typography>256 mb video memory, shader model 3.0+r</Typography>
                    <Typography>Storage: 3 GB {t('AvailableSpace')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      component="h3"
                      gutterBottom
                      className="hyphenate">
                      {t('SysReqRec')}
                    </Typography>
                    <Typography>Windows Vista {t('OrNewer')}</Typography>
                    <Typography>4 Ghz</Typography>
                    <Typography>4 GB RAM</Typography>
                    <Typography>512 mb video memory, shader model 3.0+r</Typography>
                    <Typography>Storage: 3 GB {t('AvailableSpace')}</Typography>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {/* macOS Requirements */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      component="h3"
                      gutterBottom
                      className="hyphenate">
                      {t('SysReqMin')}
                    </Typography>
                    <Typography>Mac OSX 10.10 {t('OrNewer')}</Typography>
                    <Typography>2 Ghz</Typography>
                    <Typography>2 GB RAM</Typography>
                    <Typography>256 mb video memory, OpenGL 2</Typography>
                    <Typography>500 MB {t('AvailableSpace')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      component="h3"
                      gutterBottom
                      className="hyphenate">
                      {t('SysReqRec')}
                    </Typography>
                    <Typography>Mac OSX 10.10 {t('OrNewer')}</Typography>
                    <Typography>4 Ghz</Typography>
                    <Typography>4 GB RAM</Typography>
                    <Typography>512 mb video memory, OpenGL 2</Typography>
                    <Typography>500 MB {t('AvailableSpace')}</Typography>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                {/* Linux Requirements */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      component="h3"
                      gutterBottom
                      className="hyphenate">
                      {t('SysReqMin')}
                    </Typography>
                    <Typography>Ubuntu 12.04 LTS {t('OrNewer')}</Typography>
                    <Typography>2 Ghz</Typography>
                    <Typography>2 GB RAM</Typography>
                    <Typography>256 mb video memory, OpenGL 2</Typography>
                    <Typography>500 MB {t('AvailableSpace')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      component="h3"
                      gutterBottom
                      className="hyphenate">
                      {t('SysReqRec')}
                    </Typography>
                    <Typography>Ubuntu 12.04 LTS {t('OrNewer')}</Typography>
                    <Typography>4 Ghz</Typography>
                    <Typography>4 GB RAM</Typography>
                    <Typography>512 mb video memory, OpenGL 2</Typography>
                    <Typography>500 MB {t('AvailableSpace')}</Typography>
                  </Grid>
                </Grid>
              </TabPanel>
            </CardContent>
          </Card>
        </Box>
      </Paper>
      <Fab
        color="secondary"
        aria-label={t('Upwards')}
        size="small"
        onClick={handleClick}
        sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <KeyboardArrowUpIcon />
      </Fab>
    </Container>
  );
}
