/**
 * German translation resources.
 * Each key corresponds to a phrase in English, and its value is the translated phrase in German.
 */
const de = {
  translation: {
    /**
     * Side and mobile navigation bar
     */
    Collapse: 'Zusammenklappen',
    Home: 'Startseite',
    News: 'Neuigkeiten',
    AboutTheGame: 'Über das Spiel',
    Faq: 'FAQ',
    Contact: 'Kontakt',
    AboutUs: 'Über Uns',
    Language: 'Sprache',
    DarkLightMode: 'Dunkel- / Hell-Modus',
    PreviousSlide: 'Vorherige Folie',
    NextSlide: 'Nächste Folie',
    ChangeLanguage: 'Ändere die Sprache',
    ToggleDarkLightMode: 'Wechle Dunkel Hell Modus',

    /**
     * Home
     */
    Heading0: 'Entfalte Dein Erbe auf der Ausgestoßenen Insel...',
    Heading1: '…Erbe das Geheimnis eines Zauberers oder eines Ritters…',
    Heading2: '…Schmiede dein Schicksal in einer geteilten Welt…',
    Heading3:
      '…Deine Reise beginnt hier, wo Geheimnisse und Entscheidungen auf dich warten…',
    Subtitle0:
      'Begib dich auf ein episches Abenteuer, um die vergessenen Länder zurückzuerobern!',
    Subtitle1: 'Entschlüssle die Geheimnisse eines Hauses voller Mysterien!',
    Subtitle2: 'Erforsche die Schatten, um die Wahrheit ans Licht zu bringen!',
    Subtitle3: 'Wage es, uralte Geheimnisse tief unter der Erde zu entdecken!',
    Subtitle4: 'Erarbeite dir Ruhm im alten Mühlenabenteuer!',
    Subtitle5: 'Tauche ein in die Tiefen der geheimnisvollen Mine!',
    Subtitle6: 'Löse die Rätsel der regnerischen Nacht und enthülle verborgene Pfade!',
    Subtitle7:
      'Begib dich auf eine Reise durch den verzauberten Wald und entdecke uralte Weisheit!',
    Subtitle8:
      'Setze die Segel für das Abenteuer und navigiere auf den Meeren des Schicksals!',

    /**
     * Troubleshooting Page
     */
    Troubleshooting: 'Fehlerbehebung',
    TroubleshootingHeading0: 'Noch keine Anleitungen verfügbar',
    TroubleshootingText0:
      'Da es noch keine offizielle Veröffentlichung gibt und kein Betatester das Spiel bisher gespielt hat, existieren noch keine Anleitungen',

    /**
     * News Page
     */
    English: 'Englisch',
    German: 'Deutsch',

    /**
     * News Page
     */
    NewsHeading0: 'Ankündigung des Website-Starts',
    NewsContent0:
      'Wir freuen uns, den Start unserer Spielewebsite bekannt zu geben! Bleiben Sie dran für weitere Updates.',

    /**
     * About the Game
     */
    GameDescriptionHeading: 'Spielbeschreibung',
    GameDescriptionSubHeading0: 'Entdecke Dein Erbe, Forme Dein Schicksal',
    GameDescriptionText0:
      'In “GAME_TITLE” begibst du dich auf eine epische Reise, die Zeit und Tradition überwindet. In der lebhaft vorgestellten Welt von [Game World Name] spielst du das Kind einer verbotenen Vereinigung zwischen einem edlen Zauberer und einem tapferen Ritter. Verbannt auf die abgelegene Ausgestoßenen-Insel beginnt dein Abenteuer in einem Land voller Geheimnisse, Magie und Erbe.',
    GameDescriptionSubHeading1: 'Eine Geschichte zweier Welten',
    GameDescriptionText1:
      'Enthülle die Geschichte deiner Eltern – einer Mutter aus der angesehenen Zaubererfamilie, die dem Celestine Dominion dient, und einem Vater, einem Ritter von bescheidener Herkunft, der jetzt Teil der Onyx-Koalition ist. Deine Existenz, geboren aus einer Liebe, die soziale Normen herausforderte, bringt dich auf einen Weg voller Intrigen, Konflikte und Entscheidungen, die den Lauf der Geschichte verändern könnten.',
    GameDescriptionSubHeading2: 'Erkunde die Ausgestoßenen-Insel und darüber hinaus',
    GameDescriptionText2:
      'Deine Reise beginnt auf der Ausgestoßenen-Insel, einem vergessenen Land, wo Verbannte und Geheimnisse in jedem Schatten lauern. Erkunde mysteriöse Höhlen, interagiere mit den Dorfbewohnern und entdecke die verborgenen Wahrheiten, die in der Vergangenheit deiner Familie liegen. Navigiere durch gefährliche Landschaften, von den üppigen Feldern des Dominion bis zu den rauen Geländen der Koalition.',
    GameDescriptionSubHeading3: 'Meistere die Kunst des Kampfes und der Magie',
    GameDescriptionText3:
      'Wähle deinen Weg als Ritter oder Zauberer, jeder bietet ein einzigartiges Spielerlebnis. Trainiere in der Kunst des Kampfes, perfektioniere Schwertkampf und Strategie, oder tauche in die geheimnisvollen Mysterien als Magier ein. Rüste dich mit Waffen und Zaubersprüchen aus, die das Wesen deiner Abstammung tragen.',
    GameDescriptionSubHeading4: 'Mach dich auf Entdeckungs- und Heldenquests',
    GameDescriptionText4:
      'Beteilige dich an einer Vielzahl von Quests, die deine Fähigkeiten, Entschlossenheit und Intelligenz testen werden. Von Kreaturenkämpfen bis hin zum Entschlüsseln alter Rätsel bringt dich jede Quest näher an das Verständnis der komplexen Dynamik der Welt, in der du lebst.',
    GameDescriptionSubHeading5: 'Triff Entscheidungen, die zählen',
    GameDescriptionText5:
      'Deine Entscheidungen in “GAME_TITLE” formen deine Reise und die Welt um dich herum. Verbünde dich mit Fraktionen, entscheide über das Schicksal von Charakteren, die du triffst, und entdecke mehrere Enden basierend auf deinen Handlungen. Wirst du Einheit in die geteilten Länder bringen oder sie in weiteres Chaos stürzen?',
    GameDescriptionSubHeading6: 'Eine Welt, die auf deine Fußspuren wartet:',
    GameDescriptionText6:
      '“GAME_TITLE” lädt Spieler in ein Reich ein, wo Mythen lebendig werden und Legenden geboren sind. Mit atemberaubender Grafik, fesselnder Erzählung und immersivem Gameplay ist dein Abenteuer in [Game World Name] nur durch deine Vorstellungskraft begrenzt.',
    GameDescriptionText7:
      'Bist du bereit, deine Vergangenheit zu enthüllen und deine Zukunft zu formen? Dein Erbe wartet in “GAME_TITLE”.',

    Storyline: 'Handlung und Setting',
    StorylineHeading: 'Eine geteilte Welt, ein sich entfaltendes Schicksal',
    StorylineText0:
      'Im Reich von [Game World Name] stehen das Celestine Dominion und die Onyx-Koalition am Rande des Konflikts, ihre Geschichten miteinander verflochten, doch unterschiedlich. Das Dominion, ein Land magischer Adel und Ordnung, sieht sich mit dem aufsteigenden Dissens der Koalition konfrontiert, einer Konglomerat von Nationen, reich an Ressourcen, doch belastet durch Kampf. Zwischen diesen mächtigen Fraktionen liegt die neutrale Vergoldete Enklave von Dwarrowdelf, deren zwergische Bewohner die feinsten Waffen und Juwelen schmieden, deren Zugehörigkeit ein Geheimnis ist.',
    StorylineText1:
      'Du beginnst deine Reise auf der Ausgestoßenen-Insel, einem isolierten Land, wo die Verbannten und Vergessenen zusammenkommen. Hier, inmitten von Flüstern von Rebellion und Geschichten alter Magie, enthüllst du das Erbe deiner Eltern – eine verbotene Verbindung zwischen einem edlen Zauberer und einem bescheidenen Ritter. Diese Insel, mit ihren verborgenen Geheimnissen und ungezähmten Natur, dient als Schmelztiegel für dein Schicksal. Wirst du als Retter aufsteigen oder den Untergang eines Imperiums ankündigen? Der Pfad, den du wählst, wird durch die Annalen der Geschichte hallen.',

    KeyFeatures: 'Haupteigenschaften',
    KeyFeatureSubHeading0: 'Wähle deine Klasse',
    KeyFeatureText0:
      'Nimm das Stahl des Ritters oder die geheimnisvollen Mysterien des Zauberers an. Jede Klasse bietet einen einzigartigen Spielstil und Fähigkeiten.',
    KeyFeatureSubHeading1: 'Dynamisches Erzählen',
    KeyFeatureText1:
      'Deine Entscheidungen formen die Erzählung, führen zu verschiedenen Ergebnissen und Allianzen. Erlebe eine Geschichte, die sich an deine Entscheidungen anpasst.',
    KeyFeatureSubHeading2: 'Reiche Welt-Erkundung',
    KeyFeatureText2:
      'Entdecke die vielfältigen Landschaften von [Game World Name], von den üppigen Feldern des Dominion bis zu den rauen Geländen der Koalition und den geheimnisvollen Höhen von Dwarrowdelf.',
    KeyFeatureSubHeading3: 'Spannendes Kampf- und Magiesystem',
    KeyFeatureText3:
      'Meistere ein Kampfsystem, das geschickten Schwertkampf mit mächtigen Zaubersprüchen verbindet. Passe deinen Ansatz für Schlachten und Herausforderungen an.',
    KeyFeatureSubHeading4: 'Handwerk und Ressourcenmanagement',
    KeyFeatureText4:
      'Sammle Ressourcen, stelle mächtige Gegenstände her und verwalte dein Inventar, um dich auf die bevorstehenden Abenteuer vorzubereiten.',
    KeyFeatureSubHeading5: 'Faszinierende Quests und Rätsel',
    KeyFeatureText5:
      'Löse alte Rätsel, entdecke verborgene Kammern und erfülle Quests, die die tiefe Überlieferung der Welt offenbaren.',
    KeyFeatureSubHeading6: 'Triff unvergessliche Charaktere',
    KeyFeatureText6:
      'Interagiere mit einer Besetzung von Charakteren, reich an Hintergrundgeschichten und Komplexität. Baue Beziehungen auf, die deine Reise beeinflussen.',

    Trailer: 'Trailer',
    TrailerText0:
      'Derzeit gibt es noch keinen Trailer, aber es wird in naher Zukunft ein paar davon geben.',

    CharacterProfiles: 'Charakterprofile',
    CharacterName0: 'Der Protagonist',
    CharacterText0:
      'Als Kind eines edlen Zauberers und eines tapferen Ritters stehst du am Scheideweg des Schicksals. Deine Reise von der Ausgestoßenen-Insel bis ins Herz des Dominion und darüber hinaus wird deinen wahren Zweck offenbaren.',
    CharacterName1: 'Mutter - "MOTHER_NAME',
    CharacterText1:
      'Eine weise und mächtige Zauberin des Celestine Dominion, jetzt im Exil lebend. Ihre Vergangenheit ist umhüllt in Geheimnis und Magie.',
    CharacterName2: 'Vater - "FATHER_NAME"',
    CharacterText2:
      'Ein Ritter von bescheidener Herkunft, dessen Mut und Stärke nur durch seine Hingabe an seine Familie übertroffen werden. Sein Vermächtnis ist ein Zeugnis von Mut und Widerstandsfähigkeit.',

    Upwards: 'Nach Oben',
    SysReq: 'System Vorraussetzungen',
    OrNewer: 'oder neuer',
    SysReqMin: 'Minimum',
    SysReqRec: 'Empfohlen',
    AvailableSpace: 'verfügbarer Speicherplatz',

    /**
     * FAQ
     */
    All: 'Alle',
    General: 'Generel',
    Billing: 'Abrechnung',
    Category: 'Kategorie',
    SearchFaqs: 'Suche nach FAQs',

    WhatIsIt: 'Was ist "GAME_TITLE"',
    WhatIsItAnswer:
      '"GAME_TITLE" ist ein 2D-Fantasy-Rollenspiel für Mac, Linux und Windows.',

    WhoMadeIt: 'Wer hat das Spiel gemacht?',
    WhoMadeItAnswer:
      'Das Spiel "GAME_TITLE" befindet sich noch in der Entwicklung und wurde bisher von K1ngAndu1n (Peter Stueben) solo geplant und entwickelt. Ich habe Mitte 2022 mit der Entwicklung des Spiels begonnen.',

    Publisher: 'Wer ist der Herausgeber?',
    PublisherAnswer:
      '"GAME_TITLE" ist im Eigenverlag von K1ngAndu1n (Peter Stueben) und wird voraussichtlich auf den folgenden Plattformen Mac, Linux and Windows erschienen.',

    WhereToAcquire: 'Wo kann man das Spiel erwerben?',
    WhereToAcquireAnswer:
      'Das Spiel kann auf Steam erworben werden, sobald die Entwicklung abgeschlossen ist.',

    WhatLang: 'Welche Sprachen unterstützt das Spiel?',
    WhatLangAnswer:
      'Das Spiel unterstützt derzeit Englisch und Deutsch, aber es wird noch viel mehr Sprachen geben, wenn die erste Version erscheint.',

    WhatCreatedWith: 'Womit wurde das Spiel erstellt',
    WhatCreatedWithAnswer:
      'Das Spiel wurde in Python programmiert. Das Hauptgerüst, das verwendet wurde, heißt Pygame. Zusätzlich habe ich Tiled für die Erstellung der Karten verwendet und für die Bearbeitung der Sprites habe ich Aseperite verwendet',

    WhichPatternsArchitectures: 'Welche Muster und Architekturen wurden angewendet?',
    WhichPatternsArchitecturesAnswer:
      'Als Spielarchitektur wurde das MVC-Pattern (Model View Controller) verwendet. Um Logikprobleme zu lösen, wurden die folgenden Patterns angewendet: Flyweight, Factory, Observer, State, Command und viele mehr.',

    /**
     * Contact
     */
    GetInTouch: 'Kontakt aufnehmen',
    TroubleShootingLink: 'Fehlerbehebungsleitfaden',
    CopyUrl: 'URL kopieren',
    CopyEmail: 'E-Mail kopieren',
    EmailUsAt: 'Schreiben Sie unserem Support eine Email (noch nicht fertig)',
    FollowUsOnInsta: 'Folge uns auf Insta (noch nicht fertig)',
    FollowUsOnFb: 'Like uns auf Facebook (noch nicht fertig)',

    /**
     * About Us
     */
    AboutMe: 'Über mich',
    IntroTextPara0:
      'Hallo, ich arbeite als Solutions Architect und bin ein leidenschaftlicher Spieleentwickler. Ich habe meine beruflichen Fähigkeiten und meine Liebe zum Gaming kombiniert, um [GAME_TITLE] zu erschaffen.',
    IntroTextPara1:
      'In diesem Projekt habe ich Rollen wie Webdesigner, Engineer, Architect, Developer, Grapic Designer, Game Designer, etc.',
    IntroTextPara2:
      'Ich werde angetrieben von meiner tief verwurzelten Leidenschaft für das Programmieren, einer unaufhörlichen Neugier für die Lösung komplexer Probleme und einer Begeisterung für neue Herausforderungen. Das aufregende Gefühl, unbekannte Bereiche des Fachwissens zu erobern, beflügelt meine Begeisterung. Dieses Spiel ist ein Spiegelbild meiner Leidenschaft, und ich freue mich darauf, es mit Ihnen zu teilen.',
    IntroTextPara3: 'Vernetzen Sie sich mit mir auf:',

    JourneyGameDevelopmentHeading: 'Meine Reise in der Spieleentwicklung',
    TheBeginningHeading: 'Der Anfang',
    JourneyGameDevelopmentTextBeginning:
      'Mein Einstieg in die Welt der Spieleentwicklung begann wirklich während meiner Hochschule Jahre. Es war eine Zeit der Entdeckung und Inspiration, stark beeinflusst von einem bemerkenswerten Professor, der nicht nur tief in die Spieleentwicklung eingetaucht war, sondern auch selbst mehrere Spiele entwickelt hatte. Er war für mich mehr als nur ein Lehrer; er war ein Mentor, der mich in diese faszinierende Welt führte.',
    JourneyGameDevHeading0: 'Meine ersten Kreationen',
    JourneyGameDevText0:
      'Zu Beginn tauchte ich in die Welt der textbasierten Konsolenspiele mit Python ein. Diese einfachen und leicht zu erstellenden Spiele waren meine ersten Schritte in der Programmierung und im Spieldesign. Ich erinnere mich lebhaft an die Begeisterung, diese Zeilen Code zum Leben zu erwecken und sie in interaktive Geschichten und Herausforderungen zu verwandeln.',
    JourneyGameDevHeading1: 'Die Entwicklung',
    JourneyGameDevText1:
      'Nach und nach wechselte ich zu komplexeren Kreationen, einschließlich grafischer Spiele wie einer einfachen Version von Ping Pong. Diese Phase war entscheidend, da sie meinen ersten Vorstoß in die Integration von Visualisierungen und Interaktivität auf eine anspruchsvollere Art und Weise markierte.',
    JourneyGameDevHeading2: 'Lernen durch Versuch und Irrtum',
    JourneyGameDevText2:
      'Als ich ehrgeiziger wurde, begann ich, meine eigenen Spielkonzepte zu entwickeln. Diese Periode war geprägt von Versuchen und Fehlern aufgrund meines begrenzten Wissens zu dieser Zeit. Zahlreiche Anläufe und Misserfolge lehrten mich wertvolle Lektionen in der Spieleentwicklung und Resilienz.',
    JourneyGameDevHeading3: 'Ein Meilenstein',
    JourneyGameDevText3:
      'Mein erster signifikanter Erfolg war die Nachbildung des beliebten Spiels Moorhuhn. Dieses Projekt war ein Wendepunkt, da es alle Fähigkeiten, die ich erworben hatte, vereinte und mir das Vertrauen gab, noch herausforderndere Projekte in Angriff zu nehmen.',
    JourneyGameDevHeading4: 'Aktuelle Unternehmungen',
    JourneyGameDevText4:
      'Heute bin ich voll und ganz in mein bisher aufregendstes Projekt involviert. Diese laufende Arbeit repräsentiert nicht nur eine Zusammenfassung meiner Fähigkeiten und Erfahrungen, sondern auch einen kühnen Schritt in neue, unerforschte Gebiete der Spieleentwicklung. Ich glaube, dieses Projekt wird eine bahnbrechende Ergänzung zu meinem Portfolio sein und ein Zeugnis meines Wachstums in diesem dynamischen Bereich.',
    OvercomingChallengesHeading: 'Herausforderungen Überwinden',
    JourneyGameDevelopmentTextChallenges:
      'Die Beherrschung der objektorientierten Programmierung (OOP) war entscheidend. Sie ist die Grundlage des modernen Software-Designs, und sie zu beherrschen war für meinen Fortschritt unerlässlich. Das Erlernen und Implementieren von Designmustern verbesserte meine Programmier-Effizienz erheblich. Architektonische Planung war entscheidend für die erfolgreiche Entwicklung meiner Projekte. Tiefgehende Dokumentation ist eine facettenreiche Praxis, die über das Schreiben von Docstrings hinausgeht, einschließlich der Erstellung gründlicher README-Dateien, Architekturdiagramme und die Verwendung von Fortschrittsverfolgungswerkzeugen. Die Implementierung von automatischen Formatierungs- und Linting-Tools war ein Wendepunkt für die Sicherstellung hoher Code-Qualität. Das Verständnis und die Nutzung von Type Hinting im Detail halfen mir, klareren und fehlerfreieren Code zu schreiben.',
    AUniqueApproachHeading: 'Ein Einzigartiger Ansatz',
    JourneyGameDevelopmentTextUniqueApproach:
      'Einer der markantesten Aspekte meiner Reise war die Herausforderung, im Spieleentwicklungsprozess mehrere Rollen eigenständig zu managen. Als Träger mehrerer Hüte habe ich mich nicht nur in die Spieleentwicklung vertieft, sondern auch in Spieldesign, Grafikdesign, Webdesign, Ingenieurwesen und architektonische Planung. Dieser facettenreiche Ansatz hat nicht nur meinen Fähigkeitensatz erweitert, sondern mir auch eine einzigartige Perspektive darauf gegeben, wie sich jeder Aspekt eines Spiels zu einem kohärenten und fesselnden Erlebnis zusammenfügt.',

    LookingAheadHeading: 'Blick nach Vorne',
    LookingAheadSubHeading0: 'Anfänglicher Start: Die Grundlage Bauen',
    LookingAheadText0:
      'Während wir weiterhin unsere Spielerfahrung entwickeln und verfeinern, liegt unser unmittelbarer Fokus auf dem Start der Version 0.1. Diese anfängliche Veröffentlichung, die für unsere engagierten Beta-Tester gedacht ist, ist ein entscheidender Schritt in unserem Entwicklungsprozess. Es geht nicht nur darum, die Kernfunktionen des Spiels zu enthüllen; es geht darum, eine Grundlage zu schaffen, die von Spielerfeedback und unserer Vision für das Spiel geformt wird.',
    LookingAheadSubHeading1: 'Auf dem Weg zur Version 1.0: Unsere Vision Formen',
    LookingAheadText1:
      'Unsere Reise endet nicht mit Version 0.1. Die Einblicke und Vorschläge, die wir von unseren Beta-Testern sammeln, werden unschätzbar sein, wenn wir uns auf die Veröffentlichung der Version 1.0 - unseren ersten offiziellen Start - zubewegen. Dieser Meilenstein wird die Verwirklichung unserer anfänglichen Vision markieren, aber wichtiger noch, er wird neue Wege für Wachstum und Verbesserung eröffnen.',
    LookingAheadSubHeading2: 'Kontinuierliche Verbesserung: Pläne nach dem Start',
    LookingAheadText2:
      'Nach dem Start bleibt unser Engagement für unsere Spieler beständig. Wir planen, regelmäßige Updates einzuführen, die darauf abzielen, das Gameplay zu verfeinern, Funktionen zu verbessern und auftretende Fehler zu beheben. Unser Ziel ist es, ein dynamisches Spielerlebnis zu schaffen, das sich mit seiner Community weiterentwickelt.',
    LookingAheadSubHeading3: 'Horizonte Erweitern: Das Versprechen von Version 2.0',
    LookingAheadText3:
      'Wenn wir weiter in den Horizont blicken, freuen wir uns über die Möglichkeiten von Version 2.0. Geplante Funktionen für dieses große Update umfassen Multiplayer-Fähigkeiten, aufregende Dungeons und vielleicht sogar ein Auktionshaus, um die Ingame-Ökonomie zu vertiefen. Diese Ergänzungen sind darauf ausgelegt, das Spielerlebnis zu bereichern und neue Schichten der Beteiligung und Interaktion zu bringen.',
    LookingAheadSubHeading4:
      'Über das Spielen Hinaus: Eintritt in den Merchandise-Bereich',
    LookingAheadText4:
      'Über das Spiel hinaus erforschen wir den Bereich des Merchandise. Stellen Sie sich vor, ein Stück der Spielwelt in Ihre eigene zu bringen, mit Artikeln, die mit den Abenteuern und Geschichten resonieren, die innerhalb unseres Spiels geschaffen werden.',
    LookingAheadText5:
      'Unsere Reise geht weiter, und wir freuen uns, Sie dabei zu haben. Bleiben Sie dran für weitere Updates und danke, dass Sie Teil der sich entwickelnden Geschichte unseres Spiels sind.'
  }
};

export default de;
