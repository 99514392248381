import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { checkCookieConsent, setCookieObject } from '../utilities/utilities';

/**
 * CookieConsentDialog is a React component that displays a dialog asking for user consent to use cookies.
 */
export default function CookieConsentDialog() {
  const [open, setOpen] = useState(false);

  /**
   * useEffect Hook checks for cookie consent on component mount.
   * If consent is not given, it opens the dialog.
   */
  useEffect(() => {
    if (!checkCookieConsent()) {
      setOpen(true);
    }
  }, []);

  /**
   * Handles the acceptance of cookies, sets the user settings in a cookie,
   * and closes the dialog.
   */
  const handleAccept = () => {
    const cookieData = {
      consent: true,
      language: 'en',
      theme: 'light',
      collapsed: false
    };
    setCookieObject('userSettings', cookieData);
    setOpen(false);
  };

  /**
   * Handles the closing of the dialog, ensuring it can't be closed by clicking the backdrop or pressing escape.
   */
  const handleClose = (event, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="cookie-consent-title"
      aria-describedby="cookie-consent-description"
      sx={{
        '& .MuiPaper-root': {
          boxShadow: '0px 3px 20px #00000033',
          borderRadius: 2
        }
      }}>
      <DialogContent>
        <DialogContentText id="cookie-consent-description">
          We use essential cookies to make our site work and improve user experience.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept} color="secondary" autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
