// newsData.js
const allTroubleshootingData = (t) => [
  {
    id: 'troubleshooting0',
    title: t('TroubleshootingHeading0'),
    content: t('TroubleshootingText0')
  }
];

export default allTroubleshootingData;
