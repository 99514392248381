// Faq.js
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import allFaqData from '../data/faq';

/**
 * FAQ component to display frequently asked questions.
 * It allows filtering of FAQs by categories and a search functionality.
 */
export default function Faq() {
  const { t } = useTranslation();
  const faqData = allFaqData(t);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [expanded, setExpanded] = useState(false);

  /**
   * Handles changes in the search text field.
   * @param {object} event - The event object from the TextField.
   */
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  /**
   * Handles changes in the category selection.
   * @param {object} event - The event object from the Select component.
   */
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  /**
   * Handles the expansion and collapse of an accordion panel.
   * @param {string} panel - The identifier of the panel.
   * @returns {function} A function to handle the change event.
   */
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  /**
   * Filters the FAQ data based on the selected category and search query.
   * @returns {array} The filtered FAQ data.
   */
  const getFilteredData = () => {
    if (selectedCategory === 'All') {
      return Object.values(faqData)
        .flat()
        .filter((item) => item.question.toLowerCase().includes(searchQuery));
    } else {
      return filteredData[selectedCategory];
    }
  };

  /**
   * Prepares and stores filtered FAQ data for each category.
   * The filtering is based on the current search query, comparing it with each question.
   * This results in a mapping of categories to their respective filtered questions.
   */
  const filteredData = {};
  Object.keys(faqData).forEach((category) => {
    filteredData[category] = faqData[category].filter((item) =>
      item.question.toLowerCase().includes(searchQuery)
    );
  });

  return (
    <Container maxWidth="lg">
      <Paper
        elevation={4}
        sx={{
          my: 4,
          p: 3,
          borderRadius: 2,
          backgroundColor: 'background.default',
          backgroundImage: 'none',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
        }}>
        <Box my={4}>
          <Typography variant="h3" component="h1" gutterBottom color="primary.main">
            FAQs
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', my: 2, mt: 2 }}>
            <TextField
              label={t('SearchFaqs')}
              variant="outlined"
              fullWidth
              onChange={handleSearchChange}
              InputProps={{
                // This is where you style the input text
                sx: {
                  fontSize: '20px'
                }
              }}
              InputLabelProps={{
                // This is where you style the label
                sx: {
                  fontSize: '20px',
                  color: 'primary.paper',
                  '&.Mui-focused': {
                    color: 'primary.paper'
                  }
                }
              }}
              sx={{
                backgroundColor: 'background.paper',
                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
              }}
            />
            <FormControl
              variant="outlined"
              sx={{
                minWidth: 120,
                backgroundColor: 'background.paper',
                '.MuiInputLabel-shrink': {
                  color: 'secondary.main'
                },
                '.MuiSelect-icon': {
                  color: 'primary.paper'
                },
                '.MuiOutlinedInput-input': {
                  color: 'primary.paper'
                },
                color: 'primary.paper',
                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
              }}>
              <InputLabel
                id="category-select-label"
                sx={{ color: 'primary', fontSize: '20px' }}>
                {t('Category')}
              </InputLabel>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                sx={{ fontSize: '20px' }}
                labelId="category-select-label"
                label={t('Category')}>
                <MenuItem value="All" sx={{ fontSize: '20px' }}>
                  {t('All')}
                </MenuItem>
                {Object.keys(faqData).map((category) => (
                  <MenuItem value={category} key={category} sx={{ fontSize: '20px' }}>
                    {t(category)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {getFilteredData().map((faq, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleAccordionChange(`panel${index}`)}
              sx={{
                mt: 2,
                backgroundColor: 'background.paper',
                color: 'secondary.paper',
                // '&:hover': {
                //   backgroundColor: 'secondary.main'
                // },
                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
              }}>
              <AccordionSummary
                sx={{ mt: 4 }}
                expandIcon={<ExpandMoreIcon sx={{ color: 'primary.paper' }} />}>
                <Typography sx={{ color: 'primary.paper', fontSize: '20px' }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    textAlign: 'justify',
                    color: 'primary.paper',
                    fontSize: '20px'
                  }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Paper>
    </Container>
  );
}
