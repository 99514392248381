import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Paper,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import allNewsData from '../data/news';

/**
 * `News` is a React functional component that renders a list of news items in an accordion format.
 * It uses the `useTranslation` hook for internationalization and manages the state of expanded
 * accordion items. The news data is retrieved from a utility function.
 *
 * @returns {JSX.Element} The rendered news component.
 */
export default function News() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  /**
   * Handles the change event for each accordion item.
   * Updates the state to track which accordion panel is currently expanded.
   *
   * @param {string} panel - The identifier of the panel.
   * @returns {function} A function that takes the event and isExpanded boolean.
   */
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const newsData = allNewsData(t);

  return (
    <Container maxWidth="lg">
      <Paper
        elevation={4}
        sx={{
          my: 4,
          p: 3,
          borderRadius: 2,
          backgroundColor: 'background.default',
          backgroundImage: 'none',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
        }}>
        <Box my={4}>
          <Typography variant="h3" component="h1" gutterBottom color="primary.main">
            {t('News')}
          </Typography>
          {newsData.map((newsItem) => (
            <Accordion
              key={newsItem.id}
              sx={{
                // backgroundColor: 'background.paper',
                backgroundImage: 'none',
                // '&:hover': {
                // backgroundColor: 'secondary.hover'
                // color: 'primary.paper'
                // },
                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
              }}
              expanded={expanded === newsItem.id}
              onChange={handleChange(newsItem.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'primary.paper' }} />}
                aria-controls={`${newsItem.id}bh-content`}
                id={`${newsItem.id}bh-header`}>
                <Typography
                  sx={{
                    color: 'primary.paper',
                    width: '66%',
                    flexShrink: 0,
                    fontSize: '20px'
                  }}>
                  {newsItem.title}
                </Typography>
                <Typography sx={{ color: 'primary.paper', fontSize: '20px' }}>
                  {newsItem.date}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: 'primary.paper', fontSize: '20px' }}>
                  {newsItem.content}
                </Typography>
                {/* Optionally, add an image here if your data includes one */}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Paper>
    </Container>
  );
}
