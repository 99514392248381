// newsData.js
const allNewsData = (t) => [
  {
    id: 'news0',
    title: t('NewsHeading0'),
    date: 'Jan 21, 2024',
    content: t('NewsContent0')
  }
];

export default allNewsData;
