const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: {
            main: '#C8C8C8',
            paper: '#222831'
          },
          secondary: {
            main: '#222831',
            hover: '#FFFFFF0A'
          },
          background: {
            default: '#39424e',
            paper: '#C8C8C8'
          }
        }
      : {
          // palette values for dark mode
          primary: {
            main: '#C8C8C8',
            paper: '#C8C8C8'
          },
          secondary: {
            main: '#1C1C1C',
            hover: '#FFFFFF14'
          },
          background: {
            default: '#2B2B2B',
            paper: '#434343'
          }
        })
  }
});

export { getDesignTokens };

/**
 * Colors
 *
 * '#1C1C1C' // DARK_BLACK (Text)
 * '#C8C8C8' // LIGHT_GREY (Text)
 * '#222831', // DARK_BLUE (background)
 * '#39424e', // LIGHT_BLUE (background)
 * '#080f19' // DARK_BLUE_WITH_ALPHA
 */
