// TopBar.js
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LanguageIcon from '@mui/icons-material/Language';
import LightModeIcon from '@mui/icons-material/LightMode';
import {
  AppBar,
  Box,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Toolbar
} from '@mui/material';
import * as i18n from 'i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getLanguagePreference, setLanguagePreference } from '../utilities/utilities';

/**
 * TopBar component represents the top navigation bar of the application.
 * It includes a language switch icon, a toggle switch for dark mode, and adjusts its width based on the sidebar state.
 *
 * @param {object} props Component props
 * @param {number} props.drawerWidth Width of the sidebar to adjust the TopBar's width.
 * @param {boolean} props.darkMode Indicates whether the dark mode is active.
 * @param {Function} props.toggleDarkMode Function to toggle the dark mode state.
 * @param {object} props.theme Current theme settings of the application.
 */
export default function TopBar({ drawerWidth, darkMode, toggleDarkMode }) {
  const { t } = useTranslation();
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguagePreference());

  const isLangMenuOpen = Boolean(langMenuAnchorEl);

  /**
   * Opens the language selection menu.
   * @param {object} event The event object.
   */
  const handleLanguageIconClick = (event) => {
    setLangMenuAnchorEl(event.currentTarget);
  };

  /**
   * Closes the language selection menu.
   */
  const handleLangMenuClose = () => {
    setLangMenuAnchorEl(null);
  };

  /**
   * Handles language change and updates the preference in the cookie.
   * @param {string} language The selected language.
   */
  const handleSetLanguage = (language) => {
    setLanguagePreference(language);
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    handleLangMenuClose();
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px - 5px)`,
        ml: `${drawerWidth}px`,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'background.paper',
        backgroundImage: 'none',
        boxShadow: 'none'
      }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          onClick={handleLanguageIconClick}
          aria-label={t('ChangeLanguage')}>
          <LanguageIcon sx={{ color: 'primary.paper' }} />
        </IconButton>
        <Menu
          id="language-menu"
          anchorEl={langMenuAnchorEl}
          keepMounted
          open={isLangMenuOpen}
          onClose={handleLangMenuClose}>
          <MenuItem onClick={() => handleSetLanguage('en')}>
            {t('English')}
            {selectedLanguage === 'en' && (
              <CheckCircleOutlineIcon fontSize="small" style={{ marginLeft: '8px' }} />
            )}
          </MenuItem>
          <MenuItem onClick={() => handleSetLanguage('de')}>
            {t('German')}
            {selectedLanguage === 'de' && (
              <CheckCircleOutlineIcon fontSize="small" style={{ marginLeft: '8px' }} />
            )}
          </MenuItem>
        </Menu>
        <LightModeIcon sx={{ color: darkMode ? 'action.disabled' : 'primary.paper' }} />
        <FormControlLabel
          control={
            <Switch
              checked={darkMode}
              onChange={toggleDarkMode}
              name="darkModeSwitch"
              color="default"
            />
          }
          aria-label={t('ToggleDarkLightMode')}
          labelPlacement="start"
          sx={{
            margin: 0,
            color: darkMode ? 'secondary.main' : 'action.disabled',
            '.MuiTypography-root': {
              color: darkMode ? 'secondary.main' : 'primary.paper'
            }
          }}
        />
        <DarkModeIcon sx={{ color: darkMode ? 'secondary.main' : 'action.disabled' }} />
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  drawerWidth: PropTypes.number.isRequired,
  darkMode: PropTypes.bool.isRequired,
  toggleDarkMode: PropTypes.func.isRequired
};
