import { Box, createTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import CookieConsentDialog from './components/CookieConsentDialog';
import MobileNavigation from './components/MobileNavigation';
import Sidebar from './components/Sidebar';
import { AppStyles } from './components/styled';
import TopBar from './components/Topbar';
import AboutTheGame from './pages/AboutTheGame';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import Home from './pages/Home';
import News from './pages/News';
import Troubleshooting from './pages/Troubleshooting';
import { getDesignTokens } from './utilities/themeColors';
import {
  checkCollapsePreference,
  checkThemePreference,
  setCollapsePreference,
  setThemePreference
} from './utilities/utilities';

/**
 * App.js is the root component of the React application.
 * It manages the main layout, routing, theme, and responsiveness of the application.
 * The application supports a dynamic theme for dark and light modes and adapts its layout for mobile and desktop views.
 */
export default function App() {
  // State for dark mode toggle
  const [darkMode, setDarkMode] = useState(checkThemePreference() === 'dark');

  /**
   * Function to toggle dark mode.
   * It inverses the current state of the darkMode.
   */
  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);

    // Update the theme preference in the cookie
    setThemePreference(newDarkMode ? 'dark' : 'light');
  };

  /**
   * useMemo Hook to create a theme object based on the current dark mode state.
   */
  const theme = useMemo(
    () => createTheme(getDesignTokens(darkMode ? 'dark' : 'light')),
    [darkMode]
  );

  /**
   * useMediaQuery Hook to determine if the current screen size is mobile.
   * It helps in making the UI responsive.
   */
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // State for managing the collapsed state of the sidebar.
  const [collapsed, setCollapsed] = useState(checkCollapsePreference());

  /**
   * Toggles the collapsed state of the sidebar and updates the preference in the cookie.
   */
  const toggleDrawer = () => {
    setCollapsed(!collapsed);

    // Update the collapse preference in the cookie
    setCollapsePreference(!collapsed);
  };

  /**
   * useEffect Hook to update the collapsed state based on the screen size change.
   * It adjusts the collapsed state based on screen size, but does not override user preference if it exists.
   */
  useEffect(() => {
    // Adjust the collapsed state based on screen size change,
    // but do not override user preference if it exists.
    const shouldCollapse = isMobile || checkCollapsePreference();
    setCollapsed(shouldCollapse);
  }, [isMobile]);

  // Conditional value for the width of the drawer based on its collapsed state.
  const drawerWidth = collapsed ? 70 : 240;

  return (
    <AppStyles>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {isMobile ? (
            <MobileNavigation darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          ) : (
            <TopBar
              drawerWidth={drawerWidth}
              darkMode={darkMode}
              toggleDarkMode={toggleDarkMode}
            />
          )}
          <Box sx={{ display: 'flex' }}>
            {!isMobile && (
              <Sidebar
                collapsed={collapsed}
                toggleDrawer={toggleDrawer}
                drawerWidth={drawerWidth}
                theme={theme}
              />
            )}
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 1,
                minHeight: '100vh',
                minWidth: '320px',
                backgroundColor: theme.palette.background.paper
              }}>
              <Routes>
                <Route path="/" element={<Navigate replace to="/home" />} />
                <Route path="/home" element={<Home />} />
                <Route path="/news" element={<News />} />
                <Route path="/about-the-game" element={<AboutTheGame />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/troubleshooting" element={<Troubleshooting />} />
                <Route path="/*" element={<Navigate replace to="/home" />} />
              </Routes>
            </Box>
          </Box>
        </BrowserRouter>
        <CookieConsentDialog />
      </ThemeProvider>
    </AppStyles>
  );
}
